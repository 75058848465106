import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >


<path d="M5214 8839 c-964 -46 -1889 -522 -2503 -1289 -525 -654 -786 -1461
-749 -2317 43 -1028 560 -1992 1398 -2610 490 -361 1057 -582 1685 -655 161
-18 578 -16 747 5 1249 153 2294 935 2783 2081 182 429 268 852 269 1336 1
249 -8 366 -45 585 -154 922 -700 1767 -1474 2282 -637 424 -1342 618 -2111
582z m497 -249 c1429 -144 2583 -1203 2843 -2610 42 -225 50 -323 50 -580 0
-303 -20 -479 -85 -750 -173 -720 -586 -1351 -1179 -1805 -397 -305 -910 -527
-1405 -609 -334 -56 -739 -56 -1070 0 -560 94 -1133 364 -1560 737 -630 550
-1018 1305 -1096 2133 -15 166 -6 593 16 734 59 380 157 690 319 1007 182 358
378 616 691 911 400 378 952 663 1505 777 117 24 298 52 380 58 109 8 497 6
591 -3z"/>
<path d="M5180 8564 c-434 -39 -803 -139 -1165 -315 -648 -314 -1182 -854
-1484 -1502 -408 -875 -400 -1881 24 -2742 214 -434 500 -792 865 -1082 451
-360 950 -576 1535 -665 131 -20 188 -23 445 -23 256 0 315 3 450 23 735 109
1375 446 1885 992 463 497 766 1180 824 1855 14 154 14 439 1 580 -58 622
-292 1205 -684 1698 -174 220 -466 488 -706 647 -52 35 -108 72 -123 82 -62
43 -339 180 -457 226 -397 156 -718 221 -1130 227 -129 1 -255 1 -280 -1z
m-1250 -1881 c0 -19 35 -33 84 -33 53 0 58 -9 18 -32 -15 -9 -36 -26 -46 -37
-18 -20 -18 -24 -3 -76 8 -30 11 -55 6 -55 -5 0 -28 14 -51 30 -22 17 -44 30
-48 30 -4 0 -28 -14 -54 -31 -26 -17 -49 -30 -51 -27 -2 2 2 26 10 53 8 26 15
53 15 60 0 6 -20 26 -45 45 -24 18 -42 37 -39 42 3 4 32 8 64 8 51 0 60 3 66
22 3 12 13 37 22 57 l15 36 18 -40 c10 -22 19 -45 19 -52z m370 32 c15 -53 32
-65 94 -65 25 0 46 -4 46 -10 0 -5 -3 -10 -7 -10 -5 0 -25 -15 -46 -33 l-39
-32 15 -58 c8 -31 11 -57 6 -57 -4 0 -28 14 -53 31 l-44 32 -52 -32 c-29 -18
-54 -30 -56 -28 -2 2 5 29 15 60 l20 56 -49 39 c-27 22 -46 42 -43 46 3 3 32
6 63 6 63 0 58 -4 90 83 13 36 24 29 40 -28z m384 -5 c10 -25 23 -48 29 -52 7
-4 37 -8 67 -8 l55 -1 -30 -22 c-81 -60 -79 -57 -61 -117 9 -29 16 -58 16 -63
0 -5 -23 7 -50 27 -28 20 -53 36 -58 36 -4 0 -27 -14 -52 -30 -25 -16 -48 -30
-51 -30 -4 0 1 26 12 58 l18 57 -45 40 c-24 22 -44 43 -44 48 0 4 26 7 59 7
l59 0 22 56 c13 30 26 52 30 47 4 -4 15 -28 24 -53z m376 14 c18 -64 32 -74
98 -74 l57 -1 -34 -27 c-79 -62 -73 -51 -56 -107 23 -77 20 -80 -36 -39 -46
34 -53 36 -73 24 -75 -47 -86 -52 -86 -37 0 8 7 34 16 58 l15 44 -45 41 c-25
22 -46 43 -46 47 0 3 28 7 62 9 l63 3 19 53 c10 28 22 52 26 52 4 0 13 -21 20
-46z m384 -8 c18 -60 12 -56 125 -68 23 -3 21 -6 -26 -42 l-51 -38 15 -55 c8
-30 12 -57 9 -60 -2 -3 -25 9 -50 26 -24 17 -47 31 -51 31 -4 0 -27 -14 -51
-31 -25 -17 -48 -29 -50 -26 -3 3 2 30 11 61 l16 56 -32 28 c-17 15 -38 31
-45 35 -29 17 -11 27 49 27 l63 0 19 55 c10 30 21 55 25 55 3 0 14 -24 24 -54z
m1578 -100 c24 -8 59 -17 78 -21 130 -26 310 -167 370 -289 61 -124 64 -344 8
-486 -17 -41 -35 -89 -42 -105 -31 -82 -178 -254 -252 -296 -88 -50 -82 -23
-86 -391 -2 -179 -6 -329 -9 -333 -11 -10 -77 -18 -103 -11 -25 6 -134 145
-159 203 -6 16 -29 51 -50 78 -42 55 -169 243 -195 289 -10 15 -41 59 -70 97
-42 55 -59 69 -88 75 -41 8 -61 36 -39 54 22 19 18 44 -15 93 -16 25 -30 55
-30 67 0 13 -8 24 -20 27 -19 5 -60 71 -60 98 0 8 9 15 19 15 15 0 61 56 61
76 0 2 -17 4 -39 4 -36 0 -39 -2 -45 -34 -4 -19 -14 -36 -22 -38 -13 -3 -15
-60 -14 -482 0 -264 3 -482 6 -485 3 -3 29 -10 57 -16 l52 -10 0 -50 0 -50
-190 -6 c-104 -3 -198 -1 -208 4 -14 6 -17 18 -15 55 l3 47 50 10 c28 6 56 14
63 18 9 6 12 70 12 264 0 273 -5 304 -54 338 -52 37 -97 1 -116 -93 -24 -118
-39 -167 -83 -262 -46 -97 -74 -131 -103 -124 -19 5 -33 -2 -64 -30 -22 -20
-49 -36 -61 -36 -28 0 -75 -26 -83 -46 -7 -19 -8 -17 22 -33 28 -15 28 -32 1
-57 -19 -17 -20 -26 -15 -153 4 -90 2 -139 -5 -148 -6 -7 -14 -42 -17 -78 -2
-36 -9 -68 -13 -71 -5 -3 -9 4 -9 15 0 12 -4 21 -10 21 -5 0 -10 -4 -10 -9 0
-5 -5 -22 -12 -38 -6 -15 -11 -48 -9 -73 2 -53 -13 -84 -29 -60 -6 8 -8 29 -5
45 3 17 8 67 11 112 l7 81 -33 -7 c-44 -9 -70 -46 -76 -110 -3 -28 -17 -84
-32 -124 -23 -63 -27 -89 -29 -202 -4 -208 -9 -268 -26 -300 -9 -16 -17 -41
-17 -55 0 -14 -4 -31 -9 -39 -16 -25 -30 -1 -30 52 0 29 0 57 -1 62 -5 22 1
34 18 39 33 11 39 52 26 206 -11 135 -10 148 7 184 21 43 25 82 9 88 -18 6 15
91 38 99 24 9 30 39 8 39 -10 0 -16 13 -18 37 -4 50 -31 56 -53 12 -10 -18
-21 -37 -26 -43 -16 -20 -74 -111 -87 -136 -7 -14 -23 -39 -36 -57 -12 -17
-29 -59 -36 -92 -12 -55 -11 -63 5 -81 15 -17 15 -21 2 -40 -14 -21 -12 -60 3
-60 4 0 13 7 20 15 14 16 70 20 86 6 5 -5 20 -11 34 -13 22 -3 25 -8 23 -38
-1 -19 -5 -53 -10 -75 -7 -33 -12 -40 -33 -40 -23 0 -26 -6 -36 -63 -9 -52 -9
-65 2 -69 21 -8 25 -37 10 -69 -12 -24 -13 -52 -5 -163 7 -99 6 -137 -2 -148
-21 -25 -30 2 -33 103 -2 54 -8 135 -15 179 -6 44 -9 112 -5 151 5 58 3 73
-11 87 -37 38 -48 11 -35 -84 6 -39 7 -76 3 -82 -9 -14 -11 -42 -19 -240 -4
-120 -8 -149 -21 -154 -20 -8 -33 14 -33 60 0 33 -2 36 -18 26 -28 -18 -72 14
-72 52 0 43 24 46 59 7 l30 -33 1 99 c0 86 -2 97 -15 86 -8 -7 -15 -19 -15
-26 0 -16 -26 -19 -35 -4 -3 5 10 34 29 63 27 41 36 65 36 96 0 23 7 80 17
127 13 69 14 93 4 137 -10 48 -14 53 -48 64 l-37 11 -55 -60 c-122 -132 -228
-236 -276 -268 -27 -19 -63 -46 -80 -61 -58 -50 -303 -175 -435 -221 -71 -24
-237 -35 -310 -19 -69 15 -183 83 -218 130 -14 20 -36 47 -48 61 -29 33 -39
67 -24 85 10 12 15 10 34 -13 13 -15 27 -35 33 -45 5 -10 34 -38 63 -62 80
-65 161 -88 293 -82 136 6 216 26 328 83 80 40 233 135 249 155 3 3 25 21 50
40 70 54 94 76 221 198 103 99 122 123 145 180 15 36 34 73 41 82 7 8 13 38
13 65 0 57 11 80 34 71 13 -5 15 -15 10 -49 -6 -38 -5 -41 8 -30 10 8 16 30
16 67 0 50 -2 56 -29 72 -31 18 -41 45 -54 134 -5 41 -8 46 -27 42 -21 -6 -21
-3 -6 117 3 22 -1 25 -27 25 -23 0 -31 5 -33 22 -5 33 -24 43 -97 50 -58 5
-68 9 -76 30 -22 58 -1 92 57 93 18 0 39 8 50 21 17 19 19 43 22 267 l4 247
-26 48 c-14 26 -26 54 -26 62 0 19 44 41 97 50 30 5 44 13 49 27 10 32 -9 42
-44 24 -17 -9 -36 -16 -42 -16 -7 0 -10 99 -10 306 0 191 -4 313 -10 325 -9
15 -68 49 -86 49 -10 0 -3 -317 7 -350 21 -70 -7 -111 -67 -96 -17 5 -33 24
-54 65 -16 33 -41 72 -55 87 -14 15 -25 31 -25 35 0 19 -19 7 -22 -13 -2 -13
-11 -24 -20 -26 -14 -3 -17 6 -20 55 -2 46 -7 59 -21 61 -12 2 -16 -2 -15 -15
2 -10 -1 -19 -7 -20 -5 -1 -10 7 -9 17 0 11 -5 20 -12 20 -18 0 -34 -32 -34
-67 0 -16 -7 -38 -15 -49 -8 -10 -15 -28 -15 -39 0 -12 -5 -26 -11 -32 -5 -5
-9 -34 -7 -64 l3 -54 78 -6 c76 -6 107 -19 107 -44 0 -7 7 -18 15 -25 10 -8
15 -31 15 -67 0 -56 -9 -84 -41 -119 -19 -20 -19 -21 11 -49 27 -25 30 -35 30
-86 0 -32 -6 -70 -12 -85 -25 -54 -189 -100 -205 -57 -4 10 1 22 14 32 23 19
37 80 28 120 -7 31 -51 59 -68 42 -11 -11 -12 -82 -2 -128 5 -22 3 -34 -4 -34
-35 -5 -36 -3 -37 68 -1 78 -14 106 -48 101 -26 -4 -33 -40 -11 -58 14 -12 14
-15 -1 -31 -12 -14 -15 -29 -9 -66 3 -26 9 -97 11 -156 l5 -109 -23 4 c-22 3
-23 8 -28 93 l-5 90 -8 -146 c-10 -190 -1 -219 74 -219 19 0 40 -5 47 -12 18
-18 15 -95 -4 -102 -9 -3 -134 -6 -278 -6 -190 0 -265 3 -274 12 -7 7 -12 29
-12 50 0 43 19 58 74 58 25 0 38 6 46 20 7 14 10 111 8 291 l-3 271 -26 21
c-15 12 -36 30 -48 40 -13 11 -19 22 -14 30 4 6 8 35 8 62 0 43 3 50 20 50 17
0 20 -7 20 -40 0 -33 3 -40 20 -40 20 0 20 8 23 323 2 333 -1 362 -37 362 -10
0 -29 8 -41 18 -21 17 -21 19 -6 35 27 26 81 32 131 13 42 -17 45 -16 85 4
l41 21 46 -25 46 -26 312 0 c296 0 313 1 335 20 33 29 84 35 132 17 36 -14 44
-15 69 -2 45 24 79 18 131 -24 18 -14 38 -12 105 13 134 50 394 -9 530 -119
81 -66 193 -210 193 -248 0 -4 10 -21 22 -37 15 -21 30 -30 49 -30 33 0 43 14
44 60 0 32 3 35 26 32 19 -2 29 4 37 23 12 26 16 28 72 29 30 1 35 5 38 26 2
19 -5 29 -29 43 -23 13 -29 22 -24 35 4 9 15 45 26 78 21 68 33 77 80 64 41
-12 75 7 94 53 8 19 42 53 80 82 36 28 87 66 113 86 60 45 174 109 196 109 9
0 24 4 34 9 53 27 96 41 125 41 18 0 37 4 43 9 22 22 137 26 196 7z m-1014
-380 c5 -24 -10 -38 -31 -30 -17 6 -23 35 -10 48 12 12 38 1 41 -18z m-2957
-256 c36 -20 24 -61 -31 -108 -34 -29 -60 -65 -87 -118 -35 -72 -37 -82 -37
-168 0 -126 26 -190 111 -274 63 -64 76 -95 51 -120 -23 -23 -50 -12 -108 43
-156 149 -194 380 -94 576 29 58 94 133 139 160 35 22 31 22 56 9z m122 -101
c14 -19 12 -59 -2 -59 -4 0 -26 -16 -50 -36 -56 -48 -83 -104 -89 -184 -6 -91
21 -151 99 -224 32 -30 59 -59 59 -64 0 -18 -30 -42 -52 -42 -51 0 -145 100
-180 192 -16 43 -19 70 -16 139 5 111 36 178 116 249 61 54 92 62 115 29z
m101 -65 c25 -24 19 -47 -20 -85 -49 -47 -67 -85 -67 -144 0 -60 22 -106 70
-150 42 -38 43 -74 1 -89 -58 -20 -151 98 -164 210 -11 93 35 199 111 253 36
25 48 26 69 5z m148 -166 c20 -26 23 -79 8 -109 -6 -10 -24 -26 -41 -34 -37
-19 -90 -6 -114 28 -18 26 -19 86 -2 108 35 45 116 49 149 7z m983 -486 c0
-18 -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z m1254 -283 c-16 -10
-23 -4 -14 10 3 6 11 8 17 5 6 -4 5 -9 -3 -15z m2400 -160 l24 -30 -26 -27
c-40 -42 -44 -76 -13 -106 33 -34 69 -33 95 0 24 30 27 48 9 41 -7 -2 -25 2
-41 10 -27 14 -27 14 -11 51 9 20 18 43 21 50 3 7 11 11 17 8 6 -2 35 -14 64
-26 l52 -23 0 -46 c-1 -60 -28 -118 -75 -160 -36 -31 -41 -33 -101 -29 -68 4
-101 25 -130 80 -30 58 -10 155 43 211 33 34 42 33 72 -4z m-86 -341 c61 -39
116 -75 121 -79 5 -5 -2 -25 -17 -48 -26 -39 -27 -40 -107 -47 -44 -3 -80 -10
-80 -14 0 -4 23 -21 50 -38 50 -31 51 -32 36 -59 -8 -16 -21 -36 -28 -47 -16
-21 0 -29 -181 88 l-87 56 26 40 c28 43 32 44 133 57 l65 8 -52 32 c-29 18
-52 37 -52 43 0 14 43 80 53 80 4 0 58 -32 120 -72z m-2643 -148 c0 -11 -4
-20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m2407 -217
c79 -26 143 -51 143 -55 0 -4 -14 -23 -31 -43 -23 -25 -37 -34 -50 -30 -14 5
-27 -3 -47 -26 -39 -46 -44 -57 -33 -76 7 -14 1 -27 -25 -57 -19 -22 -36 -37
-38 -35 -2 2 -38 65 -79 141 l-74 136 36 46 c20 25 40 45 46 46 5 0 74 -21
152 -47z m-2448 -90 c11 -22 11 -377 0 -395 -5 -7 -9 -11 -10 -8 0 3 -5 41
-10 85 -21 194 -29 292 -24 313 6 26 31 29 44 5z m2186 -63 l29 -30 -29 -30
c-32 -34 -35 -29 57 -125 l57 -60 -31 -32 c-18 -18 -37 -33 -42 -33 -6 0 -43
33 -84 73 -41 41 -76 68 -80 62 -4 -5 -17 -19 -30 -29 -22 -19 -22 -19 -52 9
l-30 29 97 98 c54 54 100 98 103 98 4 0 19 -13 35 -30z m-170 -301 c69 -40
128 -75 130 -79 3 -5 -11 -22 -30 -39 -27 -23 -42 -30 -60 -25 -33 8 -105 -49
-98 -77 4 -14 -4 -29 -28 -49 -19 -16 -39 -29 -45 -30 -6 0 -31 66 -56 148
l-46 147 42 37 c22 20 47 37 54 38 6 0 68 -32 137 -71z m-2425 -22 c0 -8 -9
-22 -20 -32 -11 -10 -20 -28 -20 -40 0 -13 -7 -29 -16 -36 -15 -12 -16 -10 -9
21 4 19 11 39 16 45 5 5 9 17 9 26 0 16 12 26 33 28 4 1 7 -5 7 -12z m310 -42
c16 -19 3 -57 -18 -53 -18 3 -27 30 -18 54 7 18 20 18 36 -1z m90 0 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m1720 -94
c20 -11 44 -36 58 -62 46 -85 13 -155 -109 -234 -38 -25 -74 -45 -78 -45 -9 0
-161 233 -161 247 0 7 44 35 143 91 49 27 101 28 147 3z m-1600 -26 c0 -8 -6
-15 -14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m1285 -125 c21 -40 19 -46
-15 -60 -17 -7 -33 -16 -36 -21 -2 -4 16 -49 41 -98 l45 -91 -48 -24 -48 -24
-47 94 -47 94 -40 -20 -40 -20 -20 39 c-14 28 -16 42 -9 47 33 20 226 113 236
113 7 1 19 -12 28 -29z m-255 -242 c55 -61 100 -114 100 -118 0 -4 -21 -15
-46 -25 -40 -15 -47 -15 -62 -2 -15 13 -23 13 -69 -5 -38 -14 -53 -25 -53 -38
0 -13 -15 -26 -46 -40 -26 -11 -48 -20 -50 -20 -6 0 -4 203 2 268 7 61 7 61
48 76 22 8 49 15 58 15 10 1 61 -47 118 -111z m-338 -120 c38 -157 38 -153
-29 -164 l-40 -6 -12 47 -12 47 -42 -6 c-22 -4 -42 -8 -44 -10 -1 -1 2 -18 7
-37 17 -59 14 -66 -36 -78 -26 -7 -49 -10 -51 -7 -3 2 -18 60 -34 128 -16 68
-32 131 -35 140 -4 13 4 19 33 27 63 18 68 15 82 -39 7 -27 15 -50 18 -50 3 0
22 5 43 11 l37 11 -12 53 -13 54 37 9 c71 19 66 24 103 -130z m-415 -92 c41
-76 73 -139 71 -141 -2 -1 -27 -6 -55 -10 -44 -6 -54 -4 -59 9 -5 12 -20 16
-60 16 -48 0 -56 -3 -63 -24 -7 -18 -19 -24 -57 -30 -26 -3 -49 -4 -52 -1 -4
4 67 295 75 305 5 5 92 18 111 16 8 0 48 -63 89 -140z m-307 74 l0 -39 -72 -3
c-65 -3 -73 -5 -73 -23 0 -17 8 -21 65 -25 l65 -5 0 -35 c0 -38 2 -37 -82 -39
l-53 -1 0 -46 0 -45 -52 3 -53 3 -3 148 -3 147 131 0 130 0 0 -40z"/>
<path d="M6691 6531 c-12 -21 -5 -32 15 -24 16 6 19 43 4 43 -5 0 -14 -8 -19
-19z"/>
<path d="M6887 6479 c-9 -5 -42 -11 -72 -13 -33 -2 -62 -9 -73 -19 -22 -20
-109 -67 -123 -67 -6 0 -22 -9 -36 -20 -14 -11 -37 -20 -50 -20 -16 0 -27 -7
-30 -20 -3 -10 -19 -28 -37 -38 -17 -11 -61 -45 -98 -76 -50 -42 -68 -63 -68
-81 0 -26 -26 -35 -46 -16 -5 6 -17 1 -30 -14 -19 -21 -20 -26 -8 -62 8 -21
14 -48 14 -60 0 -32 -37 -31 -52 2 -6 14 -14 25 -18 25 -19 0 -50 -40 -71 -94
-14 -35 -32 -63 -45 -68 -12 -6 -27 -21 -33 -34 -13 -29 -32 -31 -56 -4 -17
19 -18 19 -46 -23 -34 -49 -36 -67 -10 -67 30 0 37 -21 13 -39 -22 -17 -25
-30 -23 -101 l1 -45 47 -11 c32 -8 57 -8 80 -1 l33 10 0 114 0 114 30 5 c34 7
45 18 54 56 8 33 51 58 87 51 22 -4 27 0 33 21 8 31 47 50 87 41 39 -9 53 -28
39 -54 -9 -17 -8 -21 5 -21 8 0 21 -11 27 -25 9 -20 18 -25 37 -23 22 3 25 7
24 46 0 25 -7 50 -17 61 -23 25 -20 41 7 41 20 0 31 -15 66 -84 23 -46 49 -87
57 -90 8 -3 14 -13 14 -23 0 -10 14 -30 31 -44 17 -14 57 -63 88 -108 49 -73
62 -85 101 -98 67 -22 72 -29 54 -65 -18 -35 -6 -78 20 -78 29 0 35 38 37 231
1 107 6 202 11 211 5 10 18 17 30 15 11 -1 23 4 26 12 3 10 -2 12 -21 8 -35
-9 -46 9 -48 81 -2 67 10 92 45 92 12 0 26 4 32 9 5 5 25 11 44 13 l35 3 1
-48 c2 -39 -3 -54 -23 -75 -31 -34 -28 -74 7 -82 l25 -6 5 -154 5 -154 39 -10
40 -9 65 70 c77 82 77 82 131 193 53 109 63 158 58 294 -3 98 -7 118 -32 169
-72 144 -276 266 -446 266 -30 0 -63 -4 -73 -10z m319 -234 c4 -8 0 -22 -7
-31 -11 -13 -14 -13 -27 0 -17 17 -9 46 13 46 9 0 18 -7 21 -15z m-146 -14 c0
-5 14 -12 31 -16 27 -6 30 -10 25 -34 -3 -14 -9 -41 -12 -58 -7 -33 -29 -44
-50 -23 -6 6 -20 6 -40 -2 -27 -10 -35 -8 -62 12 -18 13 -32 32 -32 43 0 10
-5 28 -11 39 -9 18 -8 24 7 35 20 15 144 18 144 4z m-705 -198 c30 -45 32 -63
6 -63 -10 0 -39 -3 -64 -6 -37 -5 -48 -3 -53 9 -6 18 53 97 73 97 7 0 24 -17
38 -37z m-390 -273 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8
0 12 -4 9 -10z"/>
<path d="M6322 6264 c-12 -8 -22 -22 -22 -30 0 -8 -14 -20 -31 -26 -34 -12
-37 -41 -3 -46 14 -2 24 7 37 33 14 29 22 35 47 35 31 0 35 6 24 34 -8 20 -23
20 -52 0z"/>
<path d="M5088 6134 c-15 -8 -32 -23 -38 -34 -16 -29 -14 -570 3 -574 92 -24
95 -38 26 -105 -44 -43 -51 -54 -46 -78 3 -15 8 -120 10 -233 3 -114 10 -222
16 -242 12 -39 41 -52 41 -19 0 10 11 26 25 35 14 9 28 28 31 41 6 25 44 56
44 36 0 -5 -5 -11 -11 -13 -6 -2 -13 -25 -16 -52 -5 -40 -9 -47 -24 -43 -27 7
-43 -17 -23 -34 14 -12 19 -10 38 12 26 32 70 61 79 52 4 -3 7 -13 7 -21 0
-14 27 -41 41 -42 4 0 12 15 20 33 7 17 21 40 31 50 10 10 18 28 18 40 0 22
35 124 50 142 4 6 20 39 35 75 16 36 41 77 57 92 24 23 28 34 28 85 0 67 -4
74 -47 97 -55 28 -37 86 26 86 24 0 24 0 21 135 -2 101 -9 143 -48 263 -53
163 -138 232 -287 231 -51 0 -90 -5 -107 -15z m192 -1124 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-155 -20 c8 -13 -4
-50 -16 -50 -5 0 -9 14 -9 30 0 30 13 40 25 20z"/>
<path d="M4060 6101 c0 -11 5 -23 10 -26 15 -9 12 -35 -4 -35 -10 0 -15 -34
-21 -159 -4 -88 -4 -169 0 -180 4 -15 15 -21 34 -21 16 0 45 -7 65 -15 29 -12
36 -21 36 -43 0 -15 -7 -38 -16 -51 -23 -33 -7 -44 52 -34 46 8 49 10 47 38
-3 54 -15 115 -23 115 -15 0 -18 -13 -8 -29 12 -19 3 -91 -11 -91 -24 0 -40
238 -21 315 11 45 11 60 0 79 -10 20 -10 28 4 47 9 13 16 31 16 41 0 13 8 18
25 18 15 0 25 6 25 14 0 10 -19 16 -67 20 -38 4 -85 9 -105 11 -33 5 -38 2
-38 -14z"/>
<path d="M6990 5950 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
<path d="M5973 5883 c-7 -15 -11 -28 -9 -31 8 -7 56 30 56 44 0 24 -35 15 -47
-13z"/>
<path d="M6980 5760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5673 5635 c-8 -22 6 -33 17 -15 13 20 12 30 0 30 -6 0 -13 -7 -17
-15z"/>
<path d="M6980 5195 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M5402 4768 c-15 -15 -16 -38 -2 -38 12 0 23 25 18 40 -2 7 -7 7 -16
-2z"/>
<path d="M5109 4650 c0 -33 -17 -89 -18 -63 -1 6 -6 24 -12 40 l-11 28 -24
-49 c-26 -53 -30 -81 -14 -91 14 -8 13 -48 -1 -62 -9 -9 -9 -19 1 -42 11 -26
9 -34 -9 -60 -20 -28 -29 -81 -13 -81 4 0 25 31 47 68 33 55 40 76 41 122 2
80 2 80 29 80 24 0 25 2 19 40 -8 50 16 94 47 85 13 -4 19 -2 19 8 0 10 -13
14 -50 13 l-50 -1 -1 -35z"/>
<path d="M5340 4570 c0 -11 4 -20 9 -20 13 0 32 26 24 33 -13 14 -33 6 -33
-13z"/>
<path d="M4936 4553 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4927 4453 c-6 -56 5 -82 33 -80 24 2 25 6 3 22 -12 9 -16 25 -15 59
3 60 -14 60 -21 -1z"/>
<path d="M5447 4473 c-12 -12 -7 -43 6 -43 20 0 25 9 20 31 -5 20 -15 24 -26
12z"/>
<path d="M5295 4450 c-18 -19 -17 -20 1 -20 20 0 44 17 44 32 0 15 -27 8 -45
-12z"/>
<path d="M5377 4458 c-6 -19 4 -49 14 -42 12 7 12 54 0 54 -5 0 -11 -6 -14
-12z"/>
<path d="M4895 3970 l-29 -30 26 0 c20 0 27 6 32 26 10 37 4 38 -29 4z"/>
<path d="M7630 3944 c0 -12 31 -64 39 -64 4 0 15 8 25 18 15 18 14 20 -24 35
-22 8 -40 13 -40 11z"/>
<path d="M7191 3503 c0 -5 6 -23 12 -41 12 -31 13 -32 35 -16 12 8 20 20 17
25 -9 13 -65 41 -64 32z"/>
<path d="M6866 3305 c-19 -14 -19 -15 0 -50 10 -20 26 -43 35 -51 14 -13 19
-12 42 7 67 52 -9 145 -77 94z"/>
<path d="M6300 2962 c0 -42 5 -47 34 -36 23 9 20 27 -9 54 l-25 23 0 -41z"/>
<path d="M5547 2804 c-3 -10 -8 -29 -12 -41 -6 -20 -3 -23 24 -23 36 0 37 3
12 49 -16 30 -19 32 -24 15z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
